import React from 'react';
import Layout from '../Layout';
import "./getinspired.scss";

import sanjitaPic from "../../images/sanjitha-mohapatra-sqr.jpg"
import sreehariPic from "../../images/sreehari-suku-sqr.jpg"
import urmillaPic from "../../images/urmila-devi-sqr.jpg"

import banner from "../../images/getinspired-banner.jpg"

import galleryPic1 from "../../images/getinspired-gallery/pic1.jpg";
import galleryPic2 from "../../images/getinspired-gallery/pic2.jpg";
import galleryPic3 from "../../images/getinspired-gallery/pic3.jpg";
import galleryPic4 from "../../images/getinspired-gallery/pic4.jpg";
import galleryPic5 from "../../images/getinspired-gallery/pic5.jpg";
import galleryPic6 from "../../images/getinspired-gallery/pic6.jpg";
import galleryPic7 from "../../images/getinspired-gallery/pic7.jpg";
import galleryPic8 from "../../images/getinspired-gallery/pic8.jpg";
import galleryPic9 from "../../images/getinspired-gallery/pic9.jpg";
import galleryPic10 from "../../images/getinspired-gallery/pic10.jpg";
import galleryPic11 from "../../images/getinspired-gallery/pic11.jpg";


import SEO from '../seo';
import shareicon from "../../images/volunteer-icon-3.png"
import { RWebShare } from 'react-web-share';

const galleryData = [
    galleryPic10,
    galleryPic1,
    galleryPic5,
    galleryPic9,
    galleryPic8,
    galleryPic7,
    galleryPic3,
    galleryPic2,
    galleryPic11,
    galleryPic4,
    galleryPic6,
];

const data = [
    {
        img: sanjitaPic,
        label: "Chinmaya Vidyalaya",
        title: "BLAZING A TRAIL OF SUCCESS",
        text: "Sometimes all one needs is a single hand extended in support, to make one’s true potential shine. Sanjitha made the most of the opportunity given to her and proved her mettle.",
        link: "/get-inspired/blazing-a-trail-of-success/"
    },
    {
        img: sreehariPic,
        label: "Chinmaya Vishwa Vidyapeeth",
        title: "GIVING WINGS TO DREAMS",
        text: "Education is the key that opens the door of opportunities. Helping a child complete his/her education creates a bright future not just for him, but his entire family.",
        link: "/get-inspired/giving-wings-to-dreams/"
    },
    {
        img: urmillaPic,
        label: "CORD",
        title: "Breaking the cycle",
        text: "Empowering rural women is the key to a poverty-free Bharat. Urmila Devi’s story exemplifies triumph against untold miseries.",
        link: "/get-inspired/breaking-the-cycle/"
    },
]


const GetInspiredPage = ({ location }) => {
    return (
        <Layout>
            <SEO
                title="Chinmaya Mission | Impact Stories"
                description="When you sponsor us, you sponsor millions of lives. Read about the people directly benefiting from our social initiatives."
                keywords="Chinmaya Mission, CCMT, Impact, charity, fundraising, Chinmaya trust"
                img={`https://${location.host}/link-preview.jpg`}
            />
            <div className='getinspired'>
                <img className='getinspired__banner' src={banner}></img>
                <h1>Impact Stories</h1>

                <div className="divider"></div>

                <p>Read stories showcasing the human impact of the Chinmaya Mission’s initiatives in education and rural development.
                </p>

                <div className='getinspired__cards'>
                    {data.map(item => <div className='getinspired__cards__card'>
                        <a href={item.link} > <img className='getinspired__cards__card__img' src={item.img}></img></a>
                        <a href={item.link} className='getinspired__cards__card__body'>
                            <div className='getinspired__cards__card__label'>{item.label}</div>
                            <div className='getinspired__cards__card__title'>{item.title}</div>
                            <div className='getinspired__cards__card__text'>{item.text}</div>

                        </a>
                        <RWebShare data={{
                            text: "",
                            url: `https://${location.host}${item.link}`,
                            title: "Share this article"
                        }}><button className='share-btn'><img src={shareicon}></img></button>
                        </RWebShare>
                    </div>)}
                </div>

                <div className='getinspired__gallery'>
                    <h2>GALLERY</h2>
                    <div className="divider"></div>
                    <div className='getinspired__gallery__container'>
                        {galleryData.map(item =>
                            <img className='getinspired__gallery__pic' src={item}></img>)
                        }
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default GetInspiredPage;
